import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Sample <strong className="purple">Engagements </strong>
        </h1>
        <p style={{ color: "white" }}>
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              title="Venture Capital Firm Due Diligence"
              description={`» Retained by VC to evaluate investment potential into a drug and medical product contract manufacturer
              \n» Contract manufacturer offered diverse capabilities: Aseptic Drug Product Filling, IVD Consumable Assembly and Lyophilization Services
              \n» Provided an assessment of the relative value of their current operation, potential areas for further investment and the return on further investment`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              title="Digital Cytology Diagnostic Startup Company"
              description={`» Developed business plan including both 5 and 10 Year P&L model outlining revenue, market share from target markets and Manufacturing, SG&A and R&D costs
              \n» Prepared pitch decks for Series 'A' funding Rounds 1 and 2
              \n» Developed project plan to complete scanning system hardware development in conjunction with the Company's proprietary software`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              title="Early Stage Point of Care Diagnostics Company"
              description={`» Advisory Board Member providing counsel on operations strategy for an instrument and cartridge system that utilized novel technology to multiplex high sensitivity assay panels.`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              title="Global Medical Technology Company"
              description={`» Completed a five-year strategic plan including supporting financial models to restructure Life Sciences Division Reagent and Instrument Manufacturing operations – generating $25M in annual savings.
              \n» Developed a worldwide product distribution strategy to improve delivery lead times and lower distribution cost for a $600M annual life science reagent product line.`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              title="Next Gen Sequencing System Startup Company"
              description={`» Created a five-year manufacturing strategic plan for the instrument portfolio, built forecasts, evaluated capacity constraints and performed sensitivity analysis.
              \n» Developed a five-year strategic plan for reagents and consumables
              \n» Defined space and equipment capacity limitations and made recommendations to mitigate
              \n» Evaluated manufacturing network options considering labor, floorspace acquisition, buildout and operating cost and impact on shipping cost`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              title="Point of Care Diagnostics Startup Company"
              description={`» Established development plan and led Engineering Team in development of integrated tri-modal diagnostics system from concept through feasibility
              \n» Managed product development plans, led effort to select Alpha System and Production System supplier.`}
            />
          </Col>

          {/* New Project Cards */}
          <Col md={4} className="project-card">
            <ProjectCard
              title="Big 4 Accounting Firm"
              description={`» Worked as an expert consultant for the firm, completed an engagement to develop an integration plan for several facilities acquired by a pharmaceutical contract manufacturing company.`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              title="Point of Care Diagnostics Company"
              description={`» Advisory Board for a Point of Care Molecular Diagnostics Startup company providing them with expertise in Consumable development and manufacturing.
              \n» Supported development of a manufacturing automation strategy including should cost models for cartridges used in their high sensitivity diagnostic system.`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              title="Quest Global"
              description={`» Advised on business and marketing strategies to facilitate their successful entry into the US Medical Device market.`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              title="Chip-based Point of Care Diagnostics Company"
              description={`» Reviewed existing manufacturing scale-up plan that was late and significantly over budget
              \n» Made recommendations to simplify approach, consolidate equipment qualification activity, while still meeting regulatory and market demand requirements.`}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              title="Multi-National Medical Products Company"
              description={`» Preparing development and manufacturing strategy to manage legacy product obsolescence
              \n» Evaluating low-cost country design partners to remove barriers to R&D team and enable them to deliver per development plans.`}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
